<template>
  <section class="list container">
    <div id="results">
      <div class="preheader d-flex justify-content-between" v-if="title">
        <h2>{{ title }}</h2>
        <ToggleArrow v-if="toggle == true" @click="toggleMe = !toggleMe" section="toggleMe" :status="toggleMe">
        </ToggleArrow>
      </div>
      <div v-if="toggleMe && !!items && items.length === 0" style="margin-left: 50px">
        <header v-if="toggleMe" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            {{ noRecordsMessage }}
          </div>
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display"
              @click="triggerAction(addItems.action)">
              {{ addItems.text }}
            </button>
          </div>
        </header>
      </div>
      <div v-if="!!items && items.length > 0">
        <header v-if="toggleMe" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <span v-if="!!csvUrl && csvUrl.length > 0">
              <a class="btn btn-dark flex-0 mr-2" download @click.prevent="downloadReport(csvUrl, reportName, 'csv')">
                {{ i18n['data-table-members'].tcExportCSV }}
              </a>
              <a class="btn btn-dark flex-0 mr-2" download @click.prevent="downloadReport(pdfUrl, reportName, 'pdf')">
                {{ i18n['data-table-members'].tcExportPDF }}
              </a>
            </span>
            <b-form-input v-model="filter" @input="handleSearchTermChange"
              :placeholder="i18n['data-table-members'].tcSearch"
              class="search w-100-sd mw-100-sd mb-3 mb-sm-0 d-block w-100-sd order-1 order-sm-2"></b-form-input>
          </div>
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display"
              @click="triggerAction(addItems.action)">
              {{ addItems.text }}
            </button>
          </div>
        </header>
        <main v-if="toggleMe" class="table-responsive">
          <b-table class="dtml-table" :fields="fields" :items="items" :per-page="perPage" :current-page="currentPage"
            :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" ref="membertable" sort-icon-left small>
            <template v-slot:cell(name)="data">
              <a href="#" style="underline" @click.prevent="individualStoreKeyAndRedirect(data)">{{ data.value }}</a>
            </template>
            <template v-slot:cell(sort_name)="data">
              <a href="#" style="underline" @click.prevent="individualStoreKeyAndRedirect(data)">{{
        data.item.name
      }}</a>
            </template>
            <template v-slot:cell(dues_paid_flag)="data">
              <span
                v-if="['Renewed', 'Life Time', 'Paid -', 'Veteran', 'Autopay Enabled'].includes(data.value.indexOf('Paid') > -1 ? data.value.substring(0, 6) : data.value)">
                {{ membershipStatusActive(data.value) }}
              </span>
              <span v-if="data.value === MembershipStatusFlag.NotRenewed">
                <a href="#" @click.prevent="individualStoreKeyAndRedirectDues(data)" style="underline">{{
        membershipStatusInactive()
      }}</a>
              </span>
              <span v-if="data.value === MembershipStatusFlag.InstallmentEnabled">Installment Enabled</span>
              <span v-if="data.value === MembershipStatusFlag.PendingDrop && this.userLogin.usa_dues_season"><a href="#"
                  @click.prevent="individualStoreKeyAndRedirectDues(data)" style="underline">Pending Drop</a></span>
              <div v-if="data.value === 'checkbox'">
                <b-form-checkbox v-model="data.item.cb_paid_flag" :id="'cb_' + data.item.ind_key"
                  :name="'cb_' + data.item.ind_key" :ref="'cb_' + data.item.ind_key" value="true"
                  unchecked-value="false" class="mt-3" style="display: inline-block;"
                  @change="handleCheckboxChange($event, data)">
                </b-form-checkbox>
                <b-form-input v-model="data.item.mid_paid_date" :id="'txt_paid_date_' + data.item.ind_key"
                  :name="'txt_paid_date_' + data.item.ind_key" :ref="'txt_paid_date_' + data.item.ind_key"
                  style="max-width: 75px; font-size: 80%; padding: 0 2px; display: inline-block; height: 80%"
                  class="mr-1" @change="checkDate($event, data.item.ind_key)"></b-form-input>
              </div>
            </template>
            <template v-slot:cell(add_date)="data">{{ formatDate(data) }}</template>
            <template v-slot:cell(join_date)="data">{{ formatDate(data) }}</template>
            <template v-slot:cell(start_date)="data">{{ formatDate(data) }}</template>
          </b-table>
          <b-button name="PayDues" variant="primary" class="flex-1 btn-pl-xsm mr-4 mt-3" style="float: right"
            @click.prevent="payIODues()" v-if="campType === 'IO' && showIODues && this.userLogin.ioc_dues_season">{{
        i18n['data-table-members'].tcSubmitDues }}
          </b-button>
        </main>
        <footer v-if="toggleMe" class="d-flex align-items-center justify-content-between">
          <div class="prev" @click="prevPage" :class="{ hidden: this.currentPage == 1 }">
            <i-arrow-left />
          </div>
          <nav class="d-flex align-items-center justify-content-center">
            <div class="show d-flex align-items-center justify-content-end">
              <div class="lower">{{ i18n['data-table-members'].tcShow }}</div>
              <button :class="{ active: this.perPage == 20 }" @click="updateNumber(20)">20</button>
              <button :class="{ active: this.perPage == 50 }" @click="updateNumber(50)">50</button>
              <button :class="{ active: this.perPage == 80 }" @click="updateNumber(80)">80</button>
            </div>
            <div class="sep"></div>
            <div class="pagination justify-self">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" :hide-goto-end-buttons="true"
                @change="pageChanged"></b-pagination>
            </div>
          </nav>
          <div class="next" v-if="!!items" @click="nextPage" :class="{
        hidden: Math.ceil(this.items.length / this.perPage) == this.currentPage,
      }">
            <i-arrow-right />
          </div>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import iArrowLeft from '@/assets/svgs/arrow-left.vue'
import iArrowRight from '@/assets/svgs/arrow-right.vue'
import { numericMixin } from '@/mixins/numericMixin'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { MembershipStatusFlag } from '@/constants/MembershipStatusFlag.js'

export default {
  name: 'data-table-members',
  mixins: [numericMixin, reportDownloadMixin],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'data-table-members': {
            tcAreYouSureYouWantToProcessTheDuesOfTheAboveMember:
              'Are you sure you want to Process the Dues of the above member?',
            tcCancel: 'Cancel',
            tcExportCSV: 'Export XLS',
            tcExportPDF: 'Export PDF',
            tcLifeTime: 'Life Time',
            tcVeteran: 'Veteran',
            tcNotRenewed: 'Not Renewed',
            tcOk: 'Ok',
            tcOnceTheDuesAreProcessedTheyCannotBeRevertedPleaseBeSureBeforeYouProcessTheDues:
              'Once the Dues are processed they cannot be reverted. Please be sure before you process the dues.',
            tcPaid: 'Paid',
            tcPleaseSelectAMemberToContinue: 'Please select a member to continue.',
            tcFilter: 'Filter',
            tcRenewed: 'Renewed',
            tcSearch: 'Search',
            tcShow: 'Show',
          },
        }
      },
    },
    fields: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default: [],
    },
    csvUrl: {
      type: String,
      default: '',
    },
    pdfUrl: {
      type: String,
      default: '',
    },
    reportName: {
      type: String,
      default: 'Report',
    },
    addItems: {
      type: Object,
      default: () => ({
        text: 'Add',
        display: false,
        action: '#',
      }),
    },
    cmp_key: {
      type: String,
      default: '',
    },
    title: String,
    toggle: Boolean,
    campType: {
      type: String,
      default: 'US',
    },
    noRecordsMessage: {
      type: String,
      default: 'No records were found.',
    },
    individualType: {
      type: String,
      default: '',
    },
    showIODues: {
      type: Boolean,
      default: false,
    },
    showDuesCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Member Table Settings
      sortBy: 'Name',
      sortDesc: false,
      perPage: 20,
      currentPage: 1,
      filter: null,
      number: 20,
      pag: '',
      toggleMe: true,
    }
  },
  methods: {
    ...mapActions({
      loadIndividualProfile: 'membership/loadIndividualProfile',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSearchTerm: 'search/setSearchTerm',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    membershipStatusActive(val) {
      let rtnList = [
        { title: 'Renewed', text: this.i18n['data-table-members'].tcRenewed },
        { title: 'Life Time', text: this.i18n['data-table-members'].tcLifeTime },
        { title: 'Veteran', text: this.i18n['data-table-members'].tcVeteran },
        { title: 'Autopay Enabled', text: this.i18n['data-table-members'].tcAutoPayEnabled },
      ]
      let rtn = rtnList.find((rf) => rf.title === val)
      return !!rtn ? rtn.text : `${this.i18n['data-table-members'].tcPaid} - ${val.substring(6)}`
    },
    membershipStatusInactive() {
      return this.i18n['data-table-members'].tcNotRenewed
    },
    pageChanged() {
      document.getElementById('results').scrollIntoView()
    },
    updateNumber(num) {
      this.perPage = num
    },
    prevPage() {
      if (this.currentPage == 1) {
        return
      }
      this.currentPage--
      document.getElementById('results').scrollIntoView()
    },
    nextPage() {
      if (!this.items) {
        return 1
      }
      if (Math.ceil(this.items.length / this.perPage) == this.currentPage) {
        return
      }
      this.currentPage++
      document.getElementById('results').scrollIntoView()
    },
    triggerAction(action) {
      this.$emit(action)
    },
    type(val) {
      return typeof val
    },
    async individualStoreKeyAndRedirect(data) {
      await this.setSelectedIndividualKey(data.item.ind_key)
      switch (this.individualType) {
        case 'friend':
          this.$router.push('/programs/ff/non-member-profile-ff/')
          break
        case 'member':
          this.$router.push('/programs/mb/member-profile/')
          break
        case 'prospect':
          this.$router.push('/programs/mb/prospect-profile/')
          break
        case 'nonmember':
          this.$router.push('/programs/mb/non-member-profile/')
          break
        default:
          this.$router.push('/programs/mb/membership')
          break
      }
    },
    async payIODues() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        html: `${this.i18n['data-table-members'].tcOnceTheDuesAreProcessedTheyCannotBeRevertedPleaseBeSureBeforeYouProcessTheDues}<br>
<strong>${this.i18n['data-table-members'].tcAreYouSureYouWantToProcessTheDuesOfTheAboveMember}</strong>`,
        confirmButtonText: this.i18n['data-table-members'].tcOk,
        cancelButtonText: this.i18n['data-table-members'].tcCancel,
      }).then((result) => {
        if (result.value) {
          let paidMembers = this.items
            .filter((m) => m.cb_paid_flag === 'true')
            .map((m) => {
              return {
                ind_key: m.ind_key,
                mid_paid_date: m.mid_paid_date,
              }
            })
          if (paidMembers.length > 0) {
            this.$emit('pay_members', paidMembers)
          } else {
            this.$swal({
              icon: 'error',
              text: `${this.i18n['tcPleaseSelectAMemberToContinue']}`,
              confirmButtonText: this.i18n['data-table-members'].tcOk,
            }).then((result) => {
              return
            })
          }
        }
      })
    },
    async handleCheckboxChange(evt, data) {
      if (evt === 'true') {
        let today = this.formattedDate(new Date())
        data.item.mid_paid_date = today.toString()
      } else {
        data.item.mid_paid_date = null
      }
    },
    async handleSearchTermChange() {
      await this.setSearchTerm({ membersTerm: this.filter })
    },
    async checkDate(evt, ind_key) {
      let paidDate = new Date(evt)
      if (isNaN(paidDate)) {
        this.$swal({
          icon: 'error',
          text: this.i18n['data-table-members'].tcPleaseEnterAValidDate,
          confirmButtonText: this.i18n['data-table-members'].tcOk,
        }).then((result) => {
          let refName = 'txt_paid_date_' + ind_key
          this.$refs[refName].focus()
          return
        })
      }
    },
    async individualStoreKeyAndRedirectDues(data) {
      this.setSelectedIndividualKey(data.item.ind_key)
      try {
        await Promise.all([this.setLoadingStatus(true), this.loadIndividualProfile()]).then(() => {
          if (data.value === MembershipStatusFlag.PendingDrop && !!data.item.is_installment_member) {
            this.$router.push('/programs/mb/member-payment-center/');
          } else {
            this.$router.push('/programs/mb/member-dues/');
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    formatDate(data) {
      let returnValue = ''
      if (!!data.item.add_date) {
        returnValue = data.item.add_date.substring(0, 10)
      }
      if (!!data.item.join_date) {
        returnValue = data.item.join_date.substring(0, 10)
      }
      if (!!data.item.start_date) {
        returnValue = data.item.start_date.substring(0, 10)
      }
      return returnValue
    },
  },
  computed: {
    ...mapGetters({
      searchTerms: 'search/searchTerms',
      userLogin: 'user/userLogin',
    }),
    MembershipStatusFlag() {
      return MembershipStatusFlag
    },
    rows() {
      return !!this.items ? this.items.length : 0
    },
  },
  created() {
    this.perPage = constantData.per_page_default
    this.filter = this.searchTerms.membersTerm
  },
  components: {
    iArrowLeft: iArrowLeft,
    iArrowRight: iArrowRight,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.preheader {
  padding: 30px 32px 15px;
  align-items: center;

  @include breakpoint(sm) {
    padding-left: 23px;
    padding-right: 23px;
  }

  h2 {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4;
    line-height: 44px;
    text-transform: uppercase;

    @include breakpoint(sm) {
      padding-top: 0;
    }
  }
}

.lower {
  text-transform: lowercase;
}
</style>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.dtml-table {
  margin: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  tr {
    &:hover {
      td {
        background-color: $yellow-100;
        color: $primary-color;
      }
    }

    td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  th,
  td {
    // padding: 19px 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    border-top: none;
    vertical-align: middle;
    color: $gray-300;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    @include breakpoint(sm) {
      font-size: 18px; // Add k
      padding-right: 15px !important;
    }

    &:first-of-type {
      padding-left: 35px !important;

      @include breakpoint(sm) {
        // Add k
        padding-left: 23px !important; // Add k
      }
    }

    &:last-of-type {
      padding-right: 35px !important;

      @include breakpoint(sm) {
        // Add k
        padding-right: 20px !important; // Add k
      }
    }

    // Leaving these in for now so we can come back
    // and start tweaking as we make Mobile a priority
    // &:nth-of-type(1) {
    //   width: 350px;
    //   @include breakpoint(sm) {
    //     min-width: inherit; // Add k
    //     // min-width: 350px;
    //   }
    // }
    // &:nth-of-type(2) {
    //   width: 140px;
    //   @include breakpoint(sm) {
    //     min-width: inherit; // Add k
    //     // min-width: 140px;
    //   }
    // }
    // &:nth-of-type(3) {
    //   width: 155px;
    //   @include breakpoint(sm) {
    //     min-width: inherit; // Add k
    //     // min-width: 155px;
    //   }
    // }
    // &:nth-of-type(4) {
    //   width: 170px;
    //   @include breakpoint(sm) {
    //     min-width: inherit; // Add k
    //     // min-width: 170px;
    //   }
    // }
    // &:nth-of-type(5) {
    //   width: 160px;
    //   @include breakpoint(sm) {
    //     min-width: inherit; // Add k
    //     // min-width: 160px;
    //   }
    // }
    // &:nth-of-type(6) {
    //   width: 185px;
    //   @include breakpoint(sm) {
    //     min-width: inherit; // Add k
    //     // min-width: 185px;
    //   }
    // }
    &:after {
      display: inline-block !important;
      position: static !important;
      padding: 0 !important;
      margin-left: 10px !important;
    }

    .tooltip_text {
      font-weight: 700;
    }
  }

  th.name-left {
    width: 275px;
  }

  th.name-left.b-table-sort-icon-left,
  th.date-left.b-table-sort-icon-left {
    background-position: left 20px center !important;
  }

  th {
    color: $primary-color;

    &:after {
      color: $primary-color;
    }

    &[aria-sort='none'] {
      color: $gray-300;

      &:after {
        color: $gray-300;
      }
    }
  }

  td {}

  a {
    color: $primary-color;
    text-decoration: underline;

    &:hover {
      color: $secondary-color;
    }
  }
}
</style>
